// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
// import firebase from 'firebase';
import firebase, { getToken, onMessageListener } from "./firebase";
import { connect } from 'react-firebase';
import { Row } from 'antd';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import HomeScreenWeb from '../../components/src/HomeScreenWeb';
import TermsAndServices from '../../components/src/TermsAndServices.web';
import TopNav from '../../components/src/TopNav';
import 'antd/dist/antd.css';
// import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
// import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
// import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
// import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
// import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";

import CheckYourEmailWeb from '../../blocks/email-account-registration/src/CheckYourEmailWeb';
import EmailAccountRegistrationWeb from '../../blocks/email-account-registration/src/EmailAccountRegistrationWeb';
import VerifyEmailWeb from '../../blocks/email-account-registration/src/VerifyEmailWeb';
import EmailAccountLoginWeb from '../../blocks/email-account-login/src/EmailAccountLogin.web';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPasswordWeb';
import NewPasswordWeb from '../../blocks/forgot-password/src/NewPasswordWeb';
import LinkedAccountsWelcomeScreenWeb from '../../blocks/LinkedAccounts/src/LinkedAccountsWelcomeScreenWeb';
// import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
// import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
// import NewPassword from "../../blocks/forgot-password/src/NewPassword";
// import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
// import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
// import Subscriptions from "../../blocks/Subscriptions/src/Subscriptions";
// // import AccountsPage from "../../blocks/AccountsPage/src/AccountsPage";
import LinkedAccounts from '../../blocks/LinkedAccounts/src/LinkedAccounts.web';
// import Settings from "../../blocks/Settings/src/Settings";
// import SignUp from "../../blocks/SignUp/src/SignUp";
// import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Contactus from '../../blocks/contactus/src/Contactus.web';
// import AddContactus from "../../blocks/contactus/src/AddContactus";
// import TouchIdLogin from "../../blocks/TouchIdLogin/src/TouchIdLogin";
// import HelpCentre from "../../blocks/HelpCentre/src/HelpCentre";
// import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
// import NavigationMenu from "../../blocks/NavigationMenu/src/NavigationMenu";
// import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import StatisticsReports from "../../blocks/StatisticsReports/src/StatisticsReports.web";
// import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import SubscriptionTrialWeb from "../../blocks/dashboard/src/SubscriptionTrialWeb";
import SubScribePlanWeb from '../../blocks/dashboard/src/SubScribePlanWeb'
import SubscribePlanCard from '../../blocks/dashboard/src/SubscribePlanCard'
import AddCategories from "../../blocks/dashboard/src/AddCategories.web";
// import { getToken, onMessageListener } from './firebase';
import Transaction from "../../blocks/dashboard/src/Transaction.web";
import AlertWeb from "../../blocks/dashboard/src/Alert.web";
import MoneyReallocate from "../../blocks/dashboard/src/MoneyReallocate.web";
import SplitTransaction from "../../../packages/blocks/dashboard/src/SplitTransaction.web";
import ConfirmSplitTransaction from "../../../packages/blocks/dashboard/src/ConfirmSplitTransaction.web";
import ConfirmPayment from "../../blocks/dashboard/src/ConfirmPayment.web";
import AvailableBalance from "../../blocks/dashboard/src/AvailableBalance.web";
// import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import OnboardingScreenWeb from '../../blocks/dashboard/src/OnboardingScreenWeb';
import MonthlyNecessityExpensesWeb from '../../blocks/dashboard/src/MonthlyNecessityExpensesWeb';
import AddMonthlyNecessityExpensesWeb from '../../blocks/dashboard/src/AddMonthlyNecessityExpensesWeb';
import DiscretioneryIncomesWeb from '../../blocks/dashboard/src/DiscretioneryIncomesWeb';
import BudgetDiscretioneryIncomesWeb from '../../blocks/dashboard/src/BudgetDiscretioneryIncomesWeb';
import AddDiscretioneryExpensesWeb from '../../blocks/dashboard/src/AddDiscretioneryExpensesWeb';
import ReviewAndChecking from "../../blocks/dashboard/src/ReviewAndChecking.web";
import DiscretioneryExpenses from '../../blocks/dashboard/src/DiscretioneryExpenses.web';
import AllocateAccountWeb from '../../blocks/dashboard/src/AllocateAccountWeb';
import ReviewCheckingWeb from '../../blocks/dashboard/src/ReviewCheckingWeb';
import BudgetWeb from '../../blocks/StatisticsReports/src/BudgetWeb';
import AccountDetailsWeb from '../../blocks/AccountsPage/src/AccountDetailsWeb';
import AccountsPageWeb from '../../blocks/AccountsPage/src/AccountsPageWeb';

// import MonthlyNecessityExpenses from "../../blocks/dashboard/src/MonthlyNecessityExpenses";
// import MonthlyDiscretionaryExpenses from '../../blocks/dashboard/src/MonthlyDiscretionaryExpenses'
// import ReviewChecking from '../../blocks/dashboard/src/ReviewChecking'
import AddNecessityExpensesCategory from '../../blocks/dashboard/src/AddNecessityExpensesCategory.web';
// import AdddiscretioneryExpensesCategory from '../../blocks/dashboard/src/AdddiscretioneryExpensesCategory.web';
// import EditNecessityExpensesCategory from '../../blocks/dashboard/src/EditNecessityExpensesCategory'
import SideBar from '../../components/src/SideBar';
import TermsAndConditionAdapter from '../../blocks/adapters/src/TermsAndConditionAdapter';

const routeMap = {
  // OnboardingScreen: {
  //   component: OnboardingScreen,
  //   path: "/OnboardingScreen"
  // },
  // AdminConsole: {
  //   component: MonthlyNecessityExpenses,
  //   path: "/MonthlyNecessityExpenses"
  // },
  // AdminConsole: {
  //   component: MonthlyDiscretionaryExpenses,
  //   path: "/MonthlyDiscretionaryExpenses"
  // },
  // AdminConsole: {
  //   component: ReviewChecking,
  //   path: "/ReviewChecking"
  // },
  AddNecessityExpensesCategory: {
    component: AddNecessityExpensesCategory,
    path: "/AddNecessityExpensesCategory"
  },
  DiscretioneryExpenses: {
    component: DiscretioneryExpenses,
    path: "/DiscretioneryExpenses",
  },

  // EditNecessityExpensesCategory: {
  //   component: EditNecessityExpensesCategory,
  //   path: "/EditNecessityExpensesCategory"
  // },
  // AdminConsole: {
  //   component: AdminConsole,
  //   path: "/AdminConsole"
  // },
  // UserProfileBasicBlock: {
  //   component: UserProfileBasicBlock,
  //   path: "/UserProfileBasicBlock"
  // },
  // EmailAccountRegistration: {
  //   component: EmailAccountRegistration,
  //   path: "/EmailAccountRegistration"
  // },
  EmailAccountRegistrationWeb: {
    component: EmailAccountRegistrationWeb,
    path: '/EmailAccountRegistrationWeb'
  },
  EmailAccountLoginWeb: {
    component: EmailAccountLoginWeb,
    path: '/EmailAccountLoginWeb'
  },
  ForgotPasswordWeb: {
    component: ForgotPasswordWeb,
    path: '/ForgotPasswordWeb'
  },
  NewPasswordWeb: {
    component: NewPasswordWeb,
    path: ['/NewPasswordWeb/:email/:token', '/NewPasswordWeb/:token']
  },
  VerifyEmailWeb: {
    component: VerifyEmailWeb,
    path: '/VerifyEmailWeb/:slug'
  },
  CheckYourEmailWeb: {
    component: CheckYourEmailWeb,
    path: '/CheckYourEmailWeb'
  },
  LinkedAccountsWelcomeScreenWeb: {
    component: LinkedAccountsWelcomeScreenWeb,
    path: '/LinkedAccountsWelcomeScreenWeb'
  },
  SubscriptionTrialWeb: {
    component: SubscriptionTrialWeb,
    path: '/SubscriptionTrialWeb'
  },
  SubScribePlanWeb: {
    component: SubScribePlanWeb,
    path: '/SubScribePlanWeb'
  },
  HomeScreenWeb: {
    component: HomeScreenWeb,
    path: '/',
    exact: true
  },

  TermsAndServices: {
    component: TermsAndServices,
    path: '/TermsAndServices',
  },
  OnboardingScreenWeb: {
    component: OnboardingScreenWeb,
    path: '/OnboardingScreenWeb'
  },
  MonthlyNecessityExpensesWeb: {
    component: MonthlyNecessityExpensesWeb,
    path: '/MonthlyNecessityExpensesWeb'
  },
  AddMonthlyNecessityExpensesWeb: {
    component: AddMonthlyNecessityExpensesWeb,
    path: '/AddMonthlyNecessityExpensesWeb'
  },
  DiscretioneryIncomesWeb: {
    component: DiscretioneryIncomesWeb,
    path: '/DiscretioneryIncomesWeb'
  },
  BudgetDiscretioneryIncomesWeb: {
    component: BudgetDiscretioneryIncomesWeb,
    path: '/BudgetDiscretioneryIncomesWeb'
  },
  DiscretioneryExpenses: {
    component: DiscretioneryExpenses,
    path: '/DiscretioneryExpenses'
  }
  ,
  AddDiscretioneryExpensesWeb: {
    component: AddDiscretioneryExpensesWeb,
    path: '/AddDiscretioneryExpensesWeb'
  },
  AllocateAccountWeb: {
    component: AllocateAccountWeb,
    path: '/AllocateAccountWeb'
  },
  ReviewCheckingWeb: {
    component: ReviewCheckingWeb,
    path: '/ReviewCheckingWeb'
  },
  BudgetWeb: {
    component: BudgetWeb,
    path: '/BudgetWeb'
  },
  AccountDetailsWeb: {
    component: AccountDetailsWeb,
    path: '/AccountDetailsWeb'
  },
  AccountsPageWeb: {
    component: AccountsPageWeb,
    path: '/AccountsPageWeb/:accountId'
  },
  ReviewAndChecking: {
    component: ReviewAndChecking,
    path: '/ReviewAndChecking'
  },

  // ForgotPassword: {
  //   component: ForgotPassword,
  //   path: "/ForgotPassword"
  // },
  // ForgotPasswordOTP: {
  //   component: ForgotPasswordOTP,
  //   path: "/ForgotPasswordOTP"
  // },
  // NewPassword: {
  //   component: NewPassword,
  //   path: "/NewPassword"
  // },
  // Onboardingguide: {
  //   component: Onboardingguide,
  //   path: "/Onboardingguide"
  // },
  // RolesPermissions: {
  //   component: RolesPermissions,
  //   path: "/RolesPermissions"
  // },
  // Subscriptions: {
  //   component: Subscriptions,
  //   path: "/Subscriptions"
  // },
  // // AccountsPage: {
  // //   component: AccountsPage,
  // //   path: "/AccountsPage"
  // // },
  LinkedAccounts: {
    component: LinkedAccounts,
    path: '/LinkedAccounts'
  },
  Transaction: {
    component: Transaction,
    path: "/Transaction"
  },
  // Settings: {
  //   component: Settings,
  //   path: "/Settings"
  // },
  // SignUp: {
  //   component: SignUp,
  //   path: "/SignUp"
  // },
  // Categoriessubcategories: {
  //   component: Categoriessubcategories,
  //   path: "/Categoriessubcategories"
  // },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  // AddContactus: {
  //   component: AddContactus,
  //   path: "/AddContactus"
  // },
  // TouchIdLogin: {
  //   component: TouchIdLogin,
  //   path: "/TouchIdLogin"
  // },
  // HelpCentre: {
  //   component: HelpCentre,
  //   path: "/HelpCentre"
  // },
  // OTPInputAuth: {
  //   component: OTPInputAuth,
  //   path: "/OTPInputAuth"
  // },
  // NavigationMenu: {
  //   component: NavigationMenu,
  //   path: "/NavigationMenu"
  // },
  // EmailNotifications: {
  //   component: EmailNotifications,
  //   path: "/EmailNotifications"x
  // },
  StatisticsReports: {
    component: StatisticsReports,
    path: "/StatisticsReports"
  },
  // CountryCodeSelector: {
  //   component: CountryCodeSelector,
  //   path: "/CountryCodeSelector"
  // },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  AddCategories: {
    component: AddCategories,
    path: "/AddCategories"
  },
  Alert: {
    component: AlertWeb,
    path: "/AlertWeb"
  },
  MoneyReallocate: {
    component: MoneyReallocate,
    path: "/MoneyReallocate"
  },
  SplitTransaction: {
    component: SplitTransaction,
    path: "/SplitTransaction"
  },
  ConfirmSplitTransaction: {
    component: ConfirmSplitTransaction,
    path: "/ConfirmSplitTransaction"
  },
  ConfirmPayment: {
    component: ConfirmPayment,
    path: "/ConfirmPayment"
  },
  AvailableBalance: {
    component: AvailableBalance,
    path: "/AvailableBalance"
  }
  // EmailAccountLoginBlock: {
  //   component: EmailAccountLoginBlock,
  //   path: "/EmailAccountLoginBlock"
  // },
  // Home: {
  //   component: HomeScreen,
  //   path: '/ss',
  //   exact: true
  // },
  // InfoPage: {
  //   component: InfoPage,
  //   path: '/InfoPage'
  // },

  // AlertWeb: {
  //   component: AlertBlock,
  //   path: "/AlertWeb",
  //   modal: true
  // }
};

// const firebaseAPI = firebase.initializeApp({
//   apiKey: "AIzaSyCoxqJua-R_C8anaFt2Kr7hifH_PWpFpRk",
//   authDomain: "best-budget-97e88.firebaseapp.com",
//   projectId: "best-budget-97e88",
//   storageBucket: "best-budget-97e88.appspot.com",
//   messagingSenderId: "746547123246",
//   appId: "1:746547123246:web:378262306ffc35c8167bbf"
// });

class App extends Component {
  componentDidMount() {
    // getToken()
    onMessageListener()
    // firebase.onMessageListener().then(payload => { 
    //   console.log(payload,"payload");
    // }).catch(err => console.log('failed: ', err));
  
  }
  render() {
    // const defaultAnalytics = firebaseAPI.analytics();
    // defaultAnalytics.logEvent('APP_Loaded');
 
    return (
      <div
        className="bg_image"
        style={{
          height: 'max-content',
          minHeight: '100vh',
          backgroundColor: "#eeeeee"
        }}
      >
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </div>
    );
  }
}

export default App;

import React, { useEffect, useState } from "react";
// import logo from "../assets/header_logo.png";
const logo = require("./assets/Componente.png");
import { Row, Col, Card, Image, Divider, Input, Form, Button } from "antd";
import bgImage from "./assets/web_bg.png";
// @ts-ignore
import TopNav from "./TopNav.js";
const TermsAndServices = () => {
    return (
        <>
            <TopNav />
            <div style={styles.contentBlock}>
                <h2>Terms of Services</h2>
                <h3>General</h3>
                <p>
                    These Terms of Service, together with any documents they expressly incorporate by reference
                    (collectively, these “Terms of Service”), set forth the terms and conditions that govern your access
                    to and use of the budgeting software available through the B.E.S.T. Wealth Management mobile
                    application, software, and website, together with any associated content, including educational
                    materials and communications regarding your account, subscription, functionality and services
                    offered by B.E.S.T. Wealth Management, LLC (“B.E.S.T. Wealth Management” or “we” or “us”).
                    As used in these Terms of Service, the term “Site” refers to and includes all B.E.S.T. Wealth
                    Management websites, pages that are associated or within each website and all devices,
                    applications or services that we operate or offer that link to these Terms of Service.
                    Please read the Terms of Service carefully before you start to use the Site. By clicking to accept
                    or agree to the Terms of Service, installing, accessing, or using the Site, you accept and agree to
                    be bound and abide by these Terms of Service and our Privacy Policy, incorporated herein by
                    reference, all as they may be amended from time to time in the future. If you do not agree to these
                    Terms of Service or the Privacy Policy, you may not access or use the Site.
                    You may not use or access the Site and you may not accept these Terms of Service if you are not
                    legally authorized to accept and be bound by these terms or are not at least 18 years of age and, in
                    any event, of a legal age to form a binding contract with B.E.S.T. Wealth Management.
                    Before you continue, you should print or save a local copy of these Terms of Service for your
                    records.
                </p>

                <h3>Modifications</h3>
                <p>
                    We reserve the right at any time to modify or discontinue, either temporarily or permanently and
                    with or without notice, the Site, or any portion thereof. We will not be liable if for any reason all
                    or any part of the Site is unavailable at any time or for any period. Your use of the Site after you
                    are notified of any modifications will constitute your acceptance of and agreement to such
                    modifications. We will not be liable to you or to any third party for any modifications or
                    discontinuations of the Site.
                    We may revise and update these Terms of Service from time to time in our sole discretion. Any
                    revisions will be provided to you by electronic means (either by email or by posting the information
                    on the Sites). All changes are effective immediately upon such electronic notice and apply to all
                    access to and use of the Site thereafter. Your use of the Site following the posting of revised Terms
                    of Service means that you accept and agree to the changes.
                </p>

                <h3>Accessing the Website and Account Security</h3>
                <p>
                    To access the Site or some of the resources it offers, you may be asked to provide certain
                    registration details or other information. It is a condition of your use of the Site that all the
                    information you provide on the Site is correct, current and complete. You agree that all information
                    you provide to register with this Site or otherwise, including but not limited to using any interactive
                    features on the Site, is governed by our Privacy Policy, and you consent to all actions we take with
                    respect to your information consistent with our Privacy Policy.
                    Any username, password or any other piece of information created by or issued to you as part of
                    our security procedures, must be treated by you as confidential, and you disclose any such
                    confidential information solely at your own risk and discretion. Should you, at your discretion,
                    choose to share your username, password, or other security information with any other person or
                    entity in order to share access, we disclaim any and all liability for anything that person or entity
                    may do to compromise the security of your account.
                    You agree to notify us immediately of any unauthorized access to or use of your username or
                    password or any other breach of security. You also agree to ensure that you exit from your account
                    at the end of each session. You should use caution when accessing your account from a public or
                    shared computer so that others are not able to view or record your password or other personal
                    information.
                    We have the right to disable any username, password or other identifier, whether chosen by you
                    or provided by us, at any time if, in our opinion, you have violated any provision of these Terms
                    of Service.
                    We are not responsible for the accuracy of financial data obtained from third-party sites that are
                    displayed or reported through the Site or any of our services. We are not responsible for and cannot
                    guarantee the accuracy or timeliness of financial account we retrieve on your behalf directly, from
                    third party technology providers, from third party financial institutions where your accounts are
                    held, or from third party research/market data providers used to provide our services.
                    The Company assumes no responsibility for the timeliness, accuracy, deletion, non-delivery or
                    failure to store any user data, loss of user data, communications or personalization settings or for
                    any technical or other difficulties which may result in failure to obtain data, personalization
                    settings or service interruptions.
                </p>

                <h3>Rights You Grant to B.E.S.T. Wealth Management</h3>
                <p>
                    By submitting information, data, passwords, usernames, PINs, other log-in information, materials
                    and other content to us through the Site, you are licensing that content to us for the purpose of
                    providing our services to you through the Site. We may use and store the content in accordance
                    with these Terms of Service and our Privacy Policy. You represent that you are entitled to submit
                    such information to us for use for this purpose, without any obligation by us to pay any fees or be
                    subject to any restrictions or limitations. By using the Site, you expressly authorize us to access
                    your account information (and any personally identifying information) maintained by identified
                    third parties, on your behalf as your agent, and you expressly authorize such third parties to
                    disclose your information to us. When you use that feature of the Site, you will be directly
                    connected to the website for the third party you have identified. We will submit information
                    including usernames and passwords that you provide to log into such third party’s website. You
                    hereby authorize and permit us to use and store information submitted by you to accomplish the
                    foregoing and to configure the Site so that it is compatible with the third-party websites for which
                    you submit your information. For purposes of these Terms of Service and solely to provide the
                    account information to you as part of the services available through the Site, you grant us a limited
                    power of attorney, and appoint us as your attorney-in-fact and agent, to access third party websites,
                    retrieve and use your information with the full power and authority to do and perform each thing
                    necessary in connection with such activities, as you could do in person. YOU ACKNOWLEDGE
                    AND AGREE THAT WHEN WE ARE ACCESSING AND RETRIEVING ACCOUNT
                    INFORMATION FROM THIRD PARTY WEBSITES, WE ARE ACTING AS YOUR AGENT,
                    AND NOT AS THE AGENT OF OR ON BEHALF OF THE THIRD PARTY THAT OPERATES
                    THE THIRD-PARTY WEBSITE. You understand and agree that the Site is not sponsored or
                    endorsed by any third parties accessible through the Site. We are not responsible for any payment
                    processing errors or fees or other Site-related issues, including those issues that may arise from
                    inaccurate account information.
                </p>

                <h3>Intellectual Property Rights</h3>
                <p>
                    The Site and its entire contents, features and functionality (including but not limited to all
                    information, software, text, displays, images, video and audio, and the design, selection and
                    arrangement thereof), are owned by the Company, its licensors or other providers of such material
                    and are protected under both United States and other applicable copyright, trademark, and other
                    laws. These Terms of Service grant you the right to view and use the Site subject to these Terms
                    of Service and for your personal, non-commercial use. You may download or print a copy of
                    information for the Site for your personal, internal and non-commercial use only. You cannot
                    otherwise reproduce, distribute, modify, create derivative works of, publicly display, publicly
                    perform, republish, download, store or transmit any of the material on our Site. All rights not
                    expressly granted herein are reserved by the Company.
                    Any distribution, reprint or electronic reproduction of any content from the Site in whole or in part
                    for any purpose other than authorized in these Terms of Services is expressly prohibited without
                    our prior written consent. If you print, copy, modify, download or otherwise use or provide any
                    other person with access to any part of the Site in breach of the Terms of Service, your right to use
                    the Site will cease immediately and you must, at our option, return or destroy any copies of the
                    materials you have made.
                </p>

                <h3>Use of the Site</h3>
                <p>
                    Your right to access and use the Site is personal to you and is not transferable by you to any other
                    person or entity. You are only entitled to access and use the Site for lawful purposes and in
                    accordance with these Terms of Service. You must provide true, accurate, current and complete
                    information about your accounts maintained at other websites, as requested in our setup forms,
                    and you may not misrepresent your registration and account information. For the Site to function
                    effectively, you must also keep your registration and account information up to date and accurate.
                    If you do not do this, the accuracy and effectiveness of the Site will be affected. You represent that
                    you are a legal owner of, and that you are authorized to provide us with, all registration and account
                    information and other information necessary to facilitate your use of the Site.
                    Your access and use of the Site may be interrupted from time to time for any of several reasons,
                    including, without limitation, the malfunction of device operating environment or other equipment,
                    periodic updating, maintenance or repair of the Site or other actions that we, in our sole discretion,
                    may elect to take. In no event will we be liable to any party for any loss, cost, or damage that
                    results from any scheduled or unscheduled downtime or use of a rooted, jailbroken or incompatible
                    mobile device.
                    Use of the Site (or a portion thereof) may be available through a compatible mobile device, Internet
                    and/or network access and may require software. You are solely responsible for these
                    requirements, including any applicable changes, updates and fees as well as the terms of your
                    agreement with your mobile device and telecommunications provider. WE MAKE NO
                    WARRANTIES OR REPRESENTATIONS OF ANY KIND, EXPRESS, STATUTORY OR
                    IMPLIED AS TO: (i) THE AVAILABILITY OF TELECOMMUNICATION SERVICES FROM
                    YOUR PROVIDER AND ACCESS TO THE SERVICES AT ANY TIME OR FROM ANY
                    LOCATION; (ii) ANY LOSS, DAMAGE, OR OTHER SECURITY INTRUSION OF THE
                    TELECOMMUNICATION SERVICES; (iii) ANY LOSS, DAMAGE, OR OTHER SECURITY
                    INTRUSION FROM THE USE OF ROOTKIT MOBILE DEVICE OR YOUR DEVICE
                    OPERATING ENVIRONMENT; AND (iv) ANY DISCLOSURE OF INFORMATION TO
                    THIRD PARTIES OR FAILURE TO TRANSMIT ANY DATA, COMMUNICATIONS OR
                    SETTINGS CONNECTED WITH THE SITE.
                    Your sole and exclusive remedy for any failure or non-performance of the Site, including any
                    associated software or other materials supplied in connection with such portion of the Site, will be
                    for us to use commercially reasonable efforts to effectuate an adjustment or repair of the applicable
                    portion of the Site.
                </p>

                <h3>Access and Interference</h3>
                <p>
                    You may use the Site only in accordance with these Terms of Service. You shall not use the Site:
                    In any way that violates any applicable federal, state, local or international law or regulation
                    (including, without limitation, any laws regarding the export of data or software to and from the
                    US or other countries). To send, knowingly receive, upload, download, use or re-use any material
                    which does not comply with any standards set out in these Terms of Service. To engage in any
                    other conduct that restricts or inhibits anyone’s use or enjoyment of the Site, or which, as
                    determined by us, may harm B.E.S.T. Wealth Management or users of the Site or expose them to
                    liability.
                    Further, you shall not: Engage in behavior that will put your personal information at unnecessary
                    risk, such as leaving, transmitting, or publishing your login or passwords. Use the Site in any
                    manner that could disable, overburden, damage, or impair the Site or interfere with any other
                    party’s use of the Site. Use any robot, spider or other automatic device, process or means to access
                    the Site for any purpose, including monitoring or copying any of the material on the Site. Monitor
                    or copy any of the material on the Site without our prior written consent. Use any device, software
                    or routine that interferes with the proper working of the Site. Introduce any viruses, trojan horses,
                    worms, logic bombs or other material which is malicious or technologically harmful. Attempt to
                    gain unauthorized access to, interfere with, damage or disrupt any parts of the Site, the server on
                    which the Site is stored, or any server, computer or database connected to the Site. Otherwise
                    attempt to interfere with the proper working of the Site.
                </p>

                <h3>Monitoring and Enforcement; Termination</h3>
                <p>
                    We have the right to:
                    • Disclose your identity or other information about you to any third party who claims that
                    material posted by you violates their rights, including their intellectual property rights or
                    their right to privacy.
                    • Take appropriate legal action, including without limitation, referral to law enforcement, for
                    any illegal or unauthorized use of the Site.
                    • Terminate or suspend your access to all or part of the Website for any or no reason,
                    including without limitation, any violation of these Terms of Service.
                    Without limiting the foregoing, we have the right to fully cooperate with any law enforcement
                    authorities or court order requesting or directing us to disclose the identity or other information of
                    anyone posting any materials on or through the Site. YOU WAIVE AND HOLD HARMLESS
                    B.E.S.T. WEALTH MANAGEMENT AND ITS AFFILIATES, LICENSEES AND SERVICE
                    PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE
                    COMPANY/ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS
                    INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF
                    INVESTIGATIONS BY EITHER THE COMPANY/SUCH PARTIES OR LAW
                    ENFORCEMENT AUTHORITIES.
                </p>

                <h3>Reliance on Information Posted; Financial Information is not Financial Planning, Legal or
                    Tax Advice</h3>
                <p>
                    The information presented on or through the Site is made available solely for general information
                    purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any
                    reliance you place on such information is strictly at your own risk. We disclaim all liability and
                    responsibility arising from any reliance placed on such materials by you or any other visitor to the
                    Site, or by anyone who may be informed of any of its contents.
                    THE INFORMATION PRESENTED ON THE SITE IS NOT INTENDED TO PROVIDE
                    LEGAL, TAX OR INVESTMENT/RETIREMENT PLANNING ADVICE OR INTENDED TO
                    SERVE AS TAX PREPARATION SERVICES. The information presented on the Site is intended
                    only to assist you in your financial organization and decision-making and is broad in scope. Your
                    personal financial situation is unique, and any information and advice obtained through the Site
                    may not be appropriate for your situation. Accordingly, before making any final decisions or
                    implementing any financial strategy, you should consider obtaining additional information and
                    advice from your financial and/or legal advisers who are fully aware of your individual
                    circumstances.
                    This Site may include content provided by third parties, including materials provided by thirdparty licensors, syndicators, aggregators and/or reporting services. All statements and/or opinions
                    expressed in these materials, and all articles and responses to questions and other content are solely
                    the opinions and the responsibility of the person or entity providing those materials. These
                    materials do not necessarily reflect our opinion. We are not responsible, or liable to you or any
                    third party, for the content or accuracy of any materials provided by any third parties.
                </p>

                <h3>Information About You and Your Visits to the Website</h3>
                <p>
                    All information we collect on this Site is subject to our Privacy Policy. By using the Site, you
                    consent to all actions taken by us with respect to your information in compliance with the Privacy
                    Policy.
                </p>

                <h3>Links from the Site</h3>
                <p>
                    If the Site contains links to other sites and resources provided by third parties, these links are
                    provided for your convenience only. This includes links contained in advertisements, including
                    banner advertisements and sponsored links. We have no control over the contents of those sites or
                    resources and accept no responsibility for them or for any loss or damage that may arise from your
                    use of them. If you decide to access any of the third-party websites linked to this Site, you do so
                    entirely at your own risk and subject to the terms and conditions of use for such websites.
                </p>

                <h3>Disclaimer of Representations and Warranties</h3>
                <p>
                    THE SITE TOGETHER WITH ALL INFORMATION, DATA, FEATURES, AND ALL
                    CONTENT AND ALL SERVICES AND PRODUCTS ASSOCIATED WITH THE SITE OR
                    PROVIDED THROUGH THE SITE (WHETHER OR NOT SPONSORED) ARE PROVIDED
                    TO YOU ON AN “AS-IS” AND “AS AVAILABLE” BASIS. B.E.S.T. WEALTH
                    MANAGEMENT, ITS AFFILIATES, AND ITS THIRD-PARTY PROVIDERS, LICENSORS,
                    DISTRIBUTORS OR SUPPLIERS (COLLECTIVELY, "SUPPLIERS") MAKE NO
                    REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO
                    THE CONTENT OR OPERATION OF THE SITE. YOU EXPRESSLY AGREE THAT YOUR
                    USE OF THE SITE IS AT YOUR SOLE RISK.
                    NEITHER B.E.S.T. WEALTH MANAGEMENT OR ITS SUPPLIERS MAKE ANY
                    REPRESENTATIONS, WARRANTIES OR GUARANTEES, EXPRESS OR IMPLIED,
                    REGARDING THE ACCURACY, RELIABILITY OR COMPLETENESS OF THE CONTENT
                    ON THE SITE (WHETHER OR NOT SPONSORED), AND EXPRESSLY DISCLAIMS ANY
                    WARRANTIES OF NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR PURPOSE.
                    NEITHER B.E.S.T. WEALTH MANAGEMENT OR ITS SUPPLIERS MAKE ANY
                    REPRESENTATION, WARRANTY OR GUARANTEE THAT THE CONTENT THAT MAY
                    BE AVAILABLE THROUGH THE SITE IS FREE OF INFECTION FROM ANY VIRUSES OR
                    OTHER CODE OR ROOT KITS OR COMPUTER PROGRAMMING ROUTINES THAT
                    CONTAIN CONTAMINATING OR DESTRUCTIVE PROPERTIES OR THAT ARE
                    INTENDED TO DAMAGE, SURREPTITIOUSLY INTERCEPT OR EXPROPRIATE ANY
                    SYSTEM, DEVICE OPERATING ENVIRONMENT, DATA OR PERSONAL
                    INFORMATION.
                    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES
                    OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
                    CONSEQUENTIAL DAMAGES. IN SUCH STATES LIABILITY IS LIMITED TO THE
                    EXTENT PERMITTED BY LAW. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS
                    OF THIS PROVISION MAY NOT APPLY TO YOU.
                </p>

                <h3>Limitation on Liability</h3>
                <p>
                    IN NO EVENT WILL B.E.S.T. WEALTH MANAGEMENT, ITS AFFILIATES OR THEIR
                    LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, DIRECTORS
                    OR MANAGERS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL
                    THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO
                    USE, THE SITE, ANY SITES LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH
                    OTHER SITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR
                    SUCH OTHER SITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                    CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO,
                    PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
                    REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS
                    OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT
                    (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, ARISING IN
                    WHOLE OR IN PART FROM YOUR ACCESS TO OR USE OF THE SITE, EVEN IF WE
                    HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                    NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT, B.E.S.T.
                    WEALTH MANAGEMENT’S LIABILITY TO YOU FOR ANY CAUSE WHATEVER AND
                    REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO
                    A MAXIMUM OF $500.00 (FIVE HUNDRED UNITED STATES DOLLARS).
                    THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE
                    EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </p>

                <h3>Indemnification</h3>
                <p>
                    You shall defend, indemnify and hold harmless B.E.S.T. Wealth Management, its affiliates,
                    licensors and service providers, and its and their respective officers, directors, employees,
                    contractors, agents, licensors, suppliers, successors and assigns from and against any claims,
                    liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable
                    attorneys’ fees) arising out of or relating to your violation of these Terms of Service or your use
                    of the Site, any use of the Site’s content, services and products other than as expressly authorized
                    in these Terms of Service or your use of any information obtained from the Site.
                </p>

                <h3>Governing Law and Jurisdiction</h3>
                <p>
                    All matters relating to the Website and these Terms of Service and any dispute or claim arising
                    therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be
                    governed by and construed in accordance with the internal laws of the State of Missouri without
                    giving effect to any choice or conflict of law provision or rule.
                    Any legal suit, action or proceeding arising out of, or related to, these Terms of Service or the Site
                    shall be instituted exclusively in the federal courts of the United States or the courts of the State
                    of Missouri, in each case located in St. Louis County, Missouri, although we retain the right to
                    bring any suit, action or proceeding against you for breach of these Terms of Service in your
                    country of residence or any other relevant country. You waive any and all objections to the exercise
                    of jurisdiction over you by such courts and to a venue in such courts
                </p>

                <h3>Waiver and Severability</h3>
                <p>
                    No waiver of by the Company of any term or condition set forth in these Terms of Service shall
                    be deemed a further or continuing waiver of such term or condition or a waiver of any other term
                    or condition, and any failure of the Company to assert a right or provision under these Terms of
                    Service shall not constitute a waiver of such right or provision.
                    If any provision of these Terms of Service is held by a court or other tribunal of competent
                    jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be
                    eliminated or limited to the minimum extent such that the remaining provisions of the Terms of
                    Service will continue in full force and effect.
                </p>

                <h3>Entire Agreement</h3>
                <p>
                    The Terms of Service and our Privacy Policy and Terms of Sale constitute the sole and entire
                    agreement between you and B.E.S.T. Wealth Management with respect to the Site and supersede
                    all prior and contemporaneous understandings, agreements, representations and warranties, both
                    written and oral, with respect to the Site.
                </p>
            </div>
        </>
    );
};

const styles = {
    contentBlock: {
        height: "auto",
        padding: "40px",
        backgroundColor: "white",
        marginRight: "32px",
        border: "1px solid white",
        marginLeft: "106px",
        marginTop: "28px",
    }
}


export default TermsAndServices;
